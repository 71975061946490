import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Switch, Route } from "react-router-dom";

import Inicio from "../layout/users/inicio";

import Menu from "../layout/users/menu";

import Contacto from "../layout/users/contacto";

import Header from "../components/header/headerC";
import Footer from "../components/footer/footerC";

import { gallery1 } from "../components/Gallery1";




function UserRoutes({ routes }) {
  return (
    <div>
      <Header />
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
      <Footer />
    </div>
  );
}



const routes = [
  
  {
    path: "/",
    component: UserRoutes,
    exact: false,
    routes: [
      {
        path: "/",
        component: Inicio,
        exact: true
      },
     
      {
        path: "/menu",
        component: Menu
      },
      {
        path: "/Gallery1",
        component: gallery1
      },
      {
        path: "/contacto",
        component: Contacto
      },
      
      
      
     
    ]
  }
];


function App() {
  return (
    <HashRouter>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </HashRouter>
  );
}

export default App;

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);