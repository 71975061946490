import { Link } from "react-router-dom";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../../../Assets/logo12.jpeg'
import "./footer.css";

export default function footer() {
    return (
        <div id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="yo col-lg-3 col-md-6">
                            <div className="footer-info">
                              
                                <img className="logoo"  src={logo}/>
                               
                               
                                <p>
                                1259, Vidyaranyapura Main Rd, BEL Layout 3rd Block, Bengaluru, Karnataka 560097
                                    <br />
                                 
                                    <strong>
                                        Telefono:
                                    </strong>
                                    +91 099005 89583
                                    <br />
                                    
                                    <strong>
                                        Email:
                                    </strong>
                                    theb1pub@gmail.com
                                    <br />
                                </p>
                                <div className="social-links mt-3">
                                    <a href="/#" className="twitter"><i className="bi bi-facebook" /></a>
                                    <a href="/#" className="facebook"><i className="bi bi-twitter" /></a>
                                    <a href="/#" className="instagram"><i className="bi bi-instagram" /></a>
                                   
                                </div>
                            </div>
                        </div >
                      
                        <div className="col-lg-4 col-md-6 footer-links ">
                        
                            <ul>
                                <li>
                                    <i className="bx bx-chevron-right"></i>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <i className="bx bx-chevron-right"></i>
                                    <Link to="/nosotros">Menu</Link>
                                </li>
                                <li>
                                    <i className="bx bx-chevron-right"></i>
                                    <Link to="/menu">Gallery</Link>
                                </li>
                                
                                <li><i className="bx bx-chevron-right"></i>
                                    <Link to="/contacto">Contact Us</Link>
                                </li>
                            </ul>
                        </div>

                        

                        <div className="col-lg-5 col-md-6 footer-newsletter">
                        
                      <iframe className="f-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.4255958874655!2d77.55363183896685!3d13.072191812719073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22a402822f45%3A0xc46675a4ada35e32!2sB1%20Pub!5e0!3m2!1sen!2sin!4v1709025768812!5m2!1sen!2sin"></iframe>
                      

                        </div>
                    </div >
                </div >
            </div >

        </div>

    );
}
