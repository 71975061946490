import React from "react";
import { useEffect } from 'react';
import emailjs from "emailjs-com";
import Swal from "sweetalert2";


import "./contactenos.css";


export default function ContactoC() {
  
  useEffect(() => {
   
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  
  
   
      
      Swal.fire({
        icon: "success",
        title: "Message Sent!",
        text: "Your message has been sent successfully.",
      });
  
      
      e.target.reset();
    
  };

  return (
    <>
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <h1>Contact Us</h1>
         
          </div>
        </div>

        <div className="c-map">
          <iframe
            className="mapa"
            title="Ubicación restaurante"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.4255958874655!2d77.55363183896685!3d13.072191812719073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22a402822f45%3A0xc46675a4ada35e32!2sB1%20Pub!5e0!3m2!1sen!2sin!4v1709025768812!5m2!1sen!2sin"
          ></iframe>
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Locacion:</h4>
                  <p>1259, Vidyaranyapura Main Rd, BEL Layout 3rd Block, BEL Layout, Vidyaranyapura, Bengaluru, Karnataka 560097</p>
                </div>

                <div className="open-hours">
                  <i className="bi bi-clock"></i>
                  <h4>Timings:</h4>
                  <p>
                    Mon-Sun:
                    <br />
                    11:00 AM - 11:30 PM
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>theb1pub@gmail.com</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Phone No:</h4>
                  <p>+91 099005 89583</p>
                </div>
              </div>
            </div>

            <div className="col-lg-8 mt-5 mt-lg-0">
              <form  className="php-email-form"  onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Full name"
                      required
                    
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Mail Id"
                      required
                      
                    
                    />
                  </div>
                </div>

                

                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="8"
                    placeholder="Message"
                    required
                    
                  
                  ></textarea>
                </div>

               

                <br />

                <div className="my-3">
                  <div className="loading">Cargando</div>
                  <div className="error-message"></div>
                 
                </div>
                <div className="text-center">
                  <button type="submit">Send Your Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}


