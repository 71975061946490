import Carousel from 'react-bootstrap/Carousel';
import './Testimonial.css';

function Testimonial() {
    return (
        <>
            <section id="events" className="events">
                <div className=" container ">
                    <div className=" section-title ">
                        <div className=" titulo-seccion ">
                            <h1 style={{textAlign:'center'}}>Testimonial</h1>
                        </div>
                      
                    </div>
                </div>
                <Carousel>

                    <Carousel.Item>
                        <div className="pad row slider slider-eventos">
                            <div className="row align-items-center">
                                
                                <div className="col-md">
                                    <div className="des09 text-lg-center">
                                        <h4 style={{color:'#D3D3D3'}} >Ishita chowdhury</h4>
                                        <p style={{color:'#D3D3D3'}} >Nice ambiance. Soft music, decent crowd. Awesome food. Indian, Chinese and Italian foods are delicious. Behaviour of the staff are good. Smoking and non smoking both zone are there.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className="pad row slider slider-eventos">
                            <div className="row align-items-center">
                                
                                <div className="col-md">
                                    <div className="des09 text-lg-center">
                                        <h4 style={{color:'#D3D3D3'}} >Rithik Kumar</h4>
                                        <p style={{color:'#D3D3D3'}} >Quite good place of you want less crowd.its a good ppace with very less crowd..food is really good in here.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>


                    <Carousel.Item>
                        <div className="pad row slider slider-eventos">
                            <div className="row align-items-center">
                                
                                <div className="col-md">
                                    <div className="des09 text-lg-center">
                                        <h4 style={{color:'#D3D3D3'}} >Reji John</h4>
                                        <p style={{color:'#D3D3D3'}}  className="mb-0">Good ambience and space for Smoker and non smoker. Service and food is good also enough space for parking bikes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className="pad row slider slider-eventos">
                            <div className="row align-items-center">
                                
                                <div className="col-md">
                                    <div className="des09 text-lg-center">
                                        <h4 style={{color:'#D3D3D3'}} >Ajeet Sharma</h4>
                                        <p style={{color:'#D3D3D3'}} className="mb-0">Great place for locals to hang out and chill.
                                            Great nachos and egg chilli here.
                                            Service is great here. All staff members are cool here.
                                            It has seperate smoking area and non smoking area.
                                            Always sports events are displayed on projectors.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    
                   


                </Carousel>
            </section>
        </>
    );
}

export default Testimonial;