import React from 'react';
import './Menu1.css';
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";





const Menu1 = () => {
  return (
    <>
    <div className='m-all' >
        <div className='m-header'>
            <h1>Discover Menu</h1>

        </div>
        <br/>
        <br/>
   <div className='m-menu'>
        <div className='menu1'>
             <h2> Drink Menu</h2>
              <br/>
              
             <div className='menu11'>
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Kingfisher Ultra </h5>
                    </div>
                    
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 190</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Kingfisher Premium </h5>
                    </div>
                    
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 165</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Bira White Pitcher/ 500ml/ Mug </h5>
                    </div>
                    
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 745/250/150</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Budweiser Premium Pitcher/ 500ml/ Mug </h5>
                    </div>
                    
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 600/220/125</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Bacardi Breezer </h5>
                    </div>
                    
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 180</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Old Monk</h5>
                    </div>
                   
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 110</h6>
                    </div>
               
               
                </div>
               
                 
               
             </div>
        </div>

        <div className='menu1'>
             <h2> Food Menu</h2>
              <br/>
              
             <div className='menu11'>
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Cream of Mushroom (Veg/Non-Veg)</h5>
                    </div>
                    <div ></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 120/135</h6>
                    </div>
               
               
                </div>
                <hr  style={{borderTop:'1px dotted gray',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Mexican Finger Chips </h5>
                    </div>
                    
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 170</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Greek kabab </h5>
                    </div>
                    
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 250</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Shredded Lamb Chilly </h5>
                    </div>
                    
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 335</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Butter garlic Prawns </h5>
                    </div>
                   
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 340</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> B1’s Diced veg Burger </h5>
                    </div>
                    
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 155</h6>
                    </div>
               
               
                </div>
               
               
               
               
               
             </div>
        </div>
       

        </div>
        <Nav.Link as={Link} to="/Menu">
        <div className='b-1'>
                 <button>View Full Menu</button>
                 </div>
                 </Nav.Link>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    </>
  )
}

export default Menu1